import hljsVuePlugin from '@highlightjs/vue-plugin'
import * as Sentry from '@sentry/vue'
import axios from 'axios'
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import { replaceUrlPlaceholders, useFetchConfig } from 'num-noms'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import FocusTrap from 'primevue/focustrap'
import Tooltip from 'primevue/tooltip'
import { stringify } from 'qs'
import { registerSW } from 'virtual:pwa-register'
import { createApp } from 'vue'
import { version } from '../package.json'
import App from './App.vue'
import './assets/index.css'
import NoPrint from './directives/noPrint'
import { auth0, setAccessToken } from './plugins/auth'
import FontAwesomeIcon from './plugins/icons'
import Nomic from './presets/nomic'
import { createRouter, setPageTitleGuard } from './router'

hljs.registerLanguage('json', json)

const app = createApp(App)
const router = createRouter(app)

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENV,
  version
})

setPageTitleGuard(router)

// Periodic service worker update check
// The browser checks for updates automatically after navigations
// but what if the user doesn't navigate?
// See https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
const intervalMS = 60 * 60 * 100 // 1 hour
registerSW({
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return

        if ('connection' in navigator && !navigator.onLine) return

        // eslint-disable-next-line no-console
        console.log('Checking for service worker update')
        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache'
          }
        })

        if (resp?.status === 200) await r.update()
      }, intervalMS)
  }
})

// Set global defaults for num-noms
useFetchConfig.client = axios.create({
  baseURL: import.meta.env.VITE_API_URL + '/',
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' })
})

useFetchConfig.client.interceptors.request.use(replaceUrlPlaceholders)
useFetchConfig.client.interceptors.request.use(setAccessToken)

app
  .provide('version', version)
  .use(router) // this must be before auth0 or redirects won't work
  .use(auth0)
  .use(createPinia())
  .use(hljsVuePlugin)
  .use(PrimeVue, { unstyled: true, pt: Nomic })
  .use(ConfirmationService)
  .component('Icon', FontAwesomeIcon)
  .component('ConfirmDialog', ConfirmDialog)
  .component('ConfirmPopup', ConfirmPopup)
  .mount('#app')

app.directive('no-print', NoPrint)
app.directive('tooltip', Tooltip)
app.directive('focustrap', FocusTrap)
