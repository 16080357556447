import { createRouter as createVueRouter, createWebHistory } from 'vue-router'
import { appendPageTitle } from '@/router/guards'
import { authGuard } from '@auth0/auth0-vue'

export const ROUTE_NAMES = {
  LOGIN: 'login',
  DASHBOARD: 'dashboard',
  ACCOUNT: 'account',
  ALERT: 'alert',
  ALERTS: 'alerts',
  ASN_DETAILS: 'asn_details',
  COUNTRY_DETAILS: 'country_details',
  ERROR: 'error',
  FEED: 'feed',
  FEED_CREATE: 'feed_create',
  FEED_EDIT: 'feed_edit',
  FEEDS: 'feeds',
  FILES: 'files',
  FLOWS: 'flows',
  INACTIVE: 'inactive',
  IP_DETAILS: 'ip_details',
  LOGS: 'logs',
  LOGOUT: 'logout',
  NETWORK_DETAILS: 'network_details',
  NOT_FOUND: 'not_found',
  REPORT_PREVIEW: 'report_preview',
  REPORT: 'report',
  REPORTS: 'reports',
  SENTINEL: 'sentinel',
  SENTINELS: 'sentinels',
  SETTINGS: 'settings',
  SIGNAL: 'signal',
  SIGNALS: 'signals',
  SLACK: 'slack'
}

export function createRouter() {
  const routes = [
    {
      path: '/',
      beforeEnter: authGuard,
      component: () => import('@/components/AppLayout.vue'),
      children: [
        {
          path: 'account',
          name: ROUTE_NAMES.ACCOUNT,
          meta: { title: 'Account' },
          props: true,
          component: () => import('@/views/AccountView.vue')
        },
        {
          path: 'alerts',
          name: ROUTE_NAMES.ALERTS,
          meta: { title: 'Alerts' },
          props: true,
          component: () => import('@/views/AlertListView.vue')
        },
        {
          path: 'asn/:asn/',
          name: ROUTE_NAMES.ASN_DETAILS,
          meta: { title: 'ASN Details' },
          props: (route) => ({
            ...route.params,
            ...{ asn: parseInt(route.params.asn) }
          }),
          component: () => import('@/views/IPDetailsView.vue')
        },
        {
          path: 'country/:cc/',
          name: ROUTE_NAMES.COUNTRY_DETAILS,
          meta: { title: 'Country Details' },
          props: true,
          component: () => import('@/views/IPDetailsView.vue')
        },
        {
          path: 'dashboard',
          name: ROUTE_NAMES.DASHBOARD,
          meta: { title: 'Dashboard' },
          props: true,
          component: () => import('@/views/DashboardView.vue')
        },
        {
          path: 'feeds',
          name: ROUTE_NAMES.FEEDS,
          meta: { title: 'Feeds' },
          props: true,
          component: () => import('@/views/FeedListView.vue')
        },
        {
          path: 'feeds/create',
          name: ROUTE_NAMES.FEED_CREATE,
          meta: { title: 'Create Feed' },
          props: true,
          component: () => import('@/views/FeedCreateView.vue')
        },
        {
          path: 'feeds/:feedId',
          name: ROUTE_NAMES.FEED,
          meta: { title: 'Feed Details' },
          props: true,
          component: () => import('@/views/FeedDetailsView.vue')
        },
        {
          path: 'feeds/:feedId/edit',
          name: ROUTE_NAMES.FEED_EDIT,
          meta: { title: 'Edit Feed' },
          props: true,
          component: () => import('@/views/FeedEditView.vue')
        },
        {
          path: 'files',
          name: ROUTE_NAMES.FILES,
          meta: { title: 'Files' },
          props: true,
          component: () => import('@/views/FileListView.vue')
        },
        {
          path: 'flows',
          name: ROUTE_NAMES.FLOWS,
          meta: { title: 'Flows' },
          props: true,
          component: () => import('@/views/FlowListView.vue')
        },
        {
          path: 'logs',
          name: ROUTE_NAMES.LOGS,
          meta: { title: 'Logs' },
          props: true,
          component: () => import('@/views/AuditLogListView.vue')
        },
        {
          path: 'network/:network/',
          name: ROUTE_NAMES.IP_DETAILS,
          meta: { title: 'Network Details' },
          props: true,
          component: () => import('@/views/IPDetailsView.vue')
        },
        {
          path: 'reports/preview',
          name: ROUTE_NAMES.REPORT_PREVIEW,
          meta: { title: 'Report Preview' },
          props: true,
          component: () => import('@/views/ReportPreviewView.vue')
        },
        {
          path: 'reports/:id',
          name: ROUTE_NAMES.REPORT,
          meta: { title: 'Report Details' },
          props: true,
          component: () => import('@/views/ReportDetailView.vue')
        },
        {
          path: 'reports',
          name: ROUTE_NAMES.REPORTS,
          meta: { title: 'Reports' },
          props: true,
          component: () => import('@/views/ReportListView.vue')
        },
        {
          path: 'sentinels',
          name: ROUTE_NAMES.SENTINELS,
          meta: { title: 'Sentinels' },
          props: true,
          component: () => import('@/views/SentinelListView.vue')
        },
        {
          path: 'sentinels/:sentinelId',
          name: ROUTE_NAMES.SENTINEL,
          meta: { title: 'Sentinel Details' },
          props: true,
          component: () => import('@/views/SentinelDetailsView.vue')
        },
        {
          path: 'settings',
          name: ROUTE_NAMES.SETTINGS,
          meta: { title: 'Settings' },
          component: () => import('@/views/SettingsView.vue')
        },
        {
          path: 'signals/:id',
          name: ROUTE_NAMES.SIGNAL,
          meta: { title: 'Signal Details' },
          props: true,
          component: () => import('@/views/SignalDetailsView.vue')
        },
        {
          path: 'signals',
          name: ROUTE_NAMES.SIGNALS,
          meta: { title: 'Signals' },
          props: true,
          component: () => import('@/views/SignalListView.vue')
        },
        {
          path: 'slack',
          name: ROUTE_NAMES.SLACK,
          meta: { title: 'Slack' },
          component: () => import('@/views/SlackRedirect.vue')
        }
      ]
    },
    {
      path: '/sandbox',
      name: 'sandbox',
      meta: { title: 'Sandbox' },
      component: () => import('@/views/SandboxView.vue')
    },
    {
      path: '/inactive',
      name: ROUTE_NAMES.INACTIVE,
      meta: { title: 'Account Inactive' },
      component: () => import('@/views/Error403View.vue')
    },
    {
      path: '/logout',
      name: ROUTE_NAMES.LOGOUT,
      meta: { title: 'Logout' },
      component: () => import('@/views/LogoutView.vue')
    },
    {
      path: '/404',
      name: ROUTE_NAMES.NOT_FOUND,
      meta: { title: 'Page Not Found' },
      component: () => import('@/views/Error404View.vue')
    },
    {
      path: '/500',
      name: ROUTE_NAMES.ERROR,
      meta: { title: 'Internal Error' },
      component: () => import('@/views/Error500View.vue')
    },
    // Treat the dashboard as the default route
    {
      path: '',
      redirect: () => ({ name: ROUTE_NAMES.DASHBOARD })
    },
    // App level redirect
    {
      path: '/:pathMatch(.*)*',
      // empty params object prevents vue router warning
      // see: https://github.com/vuejs/router/issues/1617
      redirect: () => ({ name: ROUTE_NAMES.DASHBOARD, params: {} })
    }
  ]

  return createVueRouter({
    routes,
    history: createWebHistory(import.meta.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
      return savedPosition ?? { top: 0 }
    }
  })
}

export const setPageTitleGuard = (router) => {
  router.afterEach(appendPageTitle)
}
