<script setup lang="ts">
import { inject } from 'vue'

// eslint-disable-next-line no-console
console.log(
  `%cNomic HQ v${inject('version')}`,
  'color: #0082F6; font-weight: bold; font-size: 16px; padding: 5px 0;'
)
</script>

<template>
  <router-view />
</template>
